import React from 'react';
import useSeoEvent from '../LayoutSeo/useSeoEvent';
import { LOGOUT_ENDPOINT } from './authConstants';
import type { LoginPayload } from './helpers/authenticateUser';
import { authenticateUser } from './helpers/authenticateUser';
import {
  AuthenticateUserResponse,
  AuthenticateUserSuccess,
  isAuthSuccess,
} from './helpers/authTypes';
import getCurrentUser from './helpers/getCurrentUser';
import { useAuth } from './useAuth';

export default function useLogin() {
  const { trigger: triggerSeoEvent } = useSeoEvent();
  const { user, loading, error, errors, setUser, setLoading, setError, setErrors, reset } =
    useAuth();
  const [success, setSuccess] = React.useState<boolean | null>(null);

  const pingAuth = React.useCallback(async () => {
    const result = await getCurrentUser();

    if (!result) {
      reset();
      return false;
    }

    setUser(result);
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const logout = React.useCallback(async () => {
    triggerSeoEvent('logout', user?.username);
    setErrors([]);
    setSuccess(null);
    reset();
    window.location.href = LOGOUT_ENDPOINT;
  }, [reset, setErrors, triggerSeoEvent, user?.username]);

  const login = React.useCallback(
    async (payload: LoginPayload): Promise<AuthenticateUserSuccess | false> => {
      setLoading(true);
      setSuccess(null);
      setErrors([]);
      setError(null);

      const res: AuthenticateUserResponse = await authenticateUser(payload).catch((e) => {
        setError(e);
        return {
          success: false,
          errors: [
            {
              message: e instanceof Error ? e.message : 'Unknown Error',
            },
          ],
          error: e instanceof Error ? e : true,
        };
      });

      if (!isAuthSuccess(res)) {
        triggerSeoEvent('loginFailure', payload.email);
        setLoading(false);
        setErrors(res.errors?.map((e) => e.message) || []);
        setSuccess(false);
        return false;
      }

      triggerSeoEvent('loginSuccess', res.user.username);
      setLoading(false);
      setSuccess(true);

      return res;
    },
    [setError, setErrors, setLoading, triggerSeoEvent],
  );

  return {
    logout,
    login,
    pingAuth,
    success,
    user,
    loading,
    error,
    errors,
  };
}
