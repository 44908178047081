/** The current site\
 *  Either Rare Trade Centre or Sovereign Hill (default)
 */
export const SITE = process.env.NEXT_PUBLIC_SITE || 'default';

/** App in 'development' environment */
export const IS_DEV = process.env.NODE_ENV === 'development';

export const TIMEZONE = process.env.NEXT_PUBLIC_TIMEZONE ?? 'Australia/Melbourne';
export const LOCALE = process.env.NEXT_PUBLIC_LOCALE ?? 'en-AU';
export const I18N_DEFAULT_LOCALE = 'en';

export const DRAFT_MODE_ROUTE = '/api/draft';
export const QUERY_FORWARD_ROUTE = '/api/pass';

export const FAVICONS_URI = '/favicons';
export const FAVICONS_OUTPUT_DIR = `public/${FAVICONS_URI}`;

export const SEPARATOR = `•`;

export enum IMG_RATIO {
  SQUARE = 1,
  PORTRAIT = 1.42, // 3:2
  LANDSCAPE = 496 / 744, // 0.715,
  LANDSCAPE_TALL = 0.8, //
  LANDSCAPE_WIDE = 0.54, // 16:9
  BANNER = 0.4,
  VIDEO = 0.5625, // 16:9
  SCREENSHOT = 0.65, //3 / 4,
}

export type ImgRatios = typeof IMG_RATIO;
export type ImgRatio = keyof ImgRatios;

// Features
export const FEATURE = {
  RATIO_LOCK: false,
  DEV_HELPERS: IS_DEV && !!process.env.NEXT_PUBLIC_FEATURE_DEV_HELPERS,
  SNAP_TO_SLIDES: false,
  STICKY_SECTIONS: false,
  PARALLAX: !!process.env.NEXT_PUBLIC_FEATURE_PARALLAX,
  BUTTON_NAV: true,
  SCROLL_NAV: false,
  SCREENSHOTS: IS_DEV || !!process.env.NEXT_PUBLIC_FEATURE_SCREENSHOTS,
};

export const DEV_SLIDE_WATCH_IDS = process.env.NEXT_PUBLIC_DEV_SLIDE_WATCH_IDS?.split(',') ?? [];

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
export const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID;
